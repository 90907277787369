import React from 'react';

import Briefcase from '@/components/commons/icons/Briefcase';
import CircleClose from '@/components/commons/icons/CircleClose';
import CircleDistant from '@/components/commons/icons/CircleDistant';
import CircleMedium from '@/components/commons/icons/CircleMedium';
import EnvelopeSolid from '@/components/commons/icons/EnvelopeSolid';
import LinkedInIconSolid from '@/components/commons/icons/LinkedIn';
import ProfileCardSolid from '@/components/commons/icons/ProfileCardSolid';
import RocketSolid from '@/components/commons/icons/RocketSolid';
import UsersGroup from '@/components/commons/icons/UsersGroup';
import UserSolid from '@/components/commons/icons/UserSolid';
import CalendarMonth from '@/components/commons/icons/CalendarMonth';
import CircleNew from '@/components/commons/icons/CircleNew';

export const selectGroupIcon = (record) => {
    const icons = {
        Professional: <Briefcase className="w-3 h-3 text-cleanSlate" />,
        Personal: <UserSolid className="w-3 h-3 text-cleanSlate" />,
        Sports: <RocketSolid className="w-3 h-3 text-cleanSlate" />,
        Social: <UsersGroup className="w-3 h-3 text-cleanSlate" />,
    };
    return icons[record];
};

export const selectCircleIcon = (record, width = '4') => {
    const icons = {
        Close: <CircleClose className={`w-${width} h-${width}`} />,
        Medium: <CircleMedium className={`w-${width} h-${width}`} />,
        Distant: <CircleDistant className={`w-${width} h-${width}`} />,
        New: <CircleNew className={`w-${width} h-${width}`} />,
    };
    return icons[record];
};

export const selectInteractionIcon = (subject, isModal) => {
    const icons = {
        Re: <EnvelopeSolid className={`w-4 h-4 text-cleanSlate ${isModal ? 'md:w-5 h-5' : ''}`} />,
        Invitation: <CalendarMonth className={`w-4 h-4 ${isModal ? 'md:w-5 h-5' : ''}`} />,
    };
    const splittedSubject = subject?.split(':')[0];
    return icons[splittedSubject] || icons.Re;
};

export const selectBriefOrLinkedInIcon = (record) => {
    if (record.startsWith('@')) {
        return <ProfileCardSolid className="w-4 h-4 text-progressBarBlue" />;
    }
    return <LinkedInIconSolid className="w-4 h-4" />;
};

export const findContactByID = (id, data, lastInteractions) => {
    if (!data) {
        return null;
    }
    const output = {
        contactData: data.find((contact) => contact.id === id),
        lastInteraction: lastInteractions.find((el) => el.unifiedContactId === id),
    };
    return output;
};

export const filteringByVisibility = (data) => {
    if (data === 'invisible-only') {
        return false;
    } if (data === 'visible-only') {
        return true;
    }
    return null;
};

export const filteringByCircle = (data) => {
    if (data === 'close') {
        return [0];
    } if (data === 'medium') {
        return [1];
    } if (data === 'distant') {
        return [2];
    }
    return null;
};

export const groupContactsByCircle = (unifiedContacts) => {
    const counts = {
        0: 0, 1: 0, 2: 0, 3: 0,
    };

    unifiedContacts.forEach((contact) => {
        const circle = contact.proposedCircle;
        if (circle in counts) {
            counts[circle] += 1;
        }
    });

    const totalContacts = unifiedContacts.length;
    const percentages = {
        0: Math.round((counts[0] / totalContacts) * 100),
        1: Math.round((counts[1] / totalContacts) * 100),
        2: Math.round((counts[2] / totalContacts) * 100),
        3: Math.round((counts[3] / totalContacts) * 100),
    };

    return percentages;
};

export const sumInteractionsByProposedCircle = (unifiedContacts) => {
    const sums = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
    };

    unifiedContacts.forEach((contact) => {
        const circle = contact.proposedCircle;
        if (circle in sums) {
            sums[circle] += contact.interactionsCount;
        }
    });

    return sums;
};

export const birthdaySoon = (contactBirthday, fullName) => {
    if (!contactBirthday) {
        return null;
    }
    const [year, month, day] = contactBirthday.split('-');

    const birthday = new Date(year, month - 1, day);
    const actualDate = new Date();
    const today = new Date(actualDate.getTime());
    const firstName = fullName.split(' ')[0];

    birthday.setFullYear(today.getFullYear());

    if (today.getMonth() > birthday.getMonth()
        || (
            today.getMonth() === birthday.getMonth()
            && today.getDate() > birthday.getDate()
        )
    ) {
        birthday.setFullYear(today.getFullYear() + 1);
    }

    const diffTime = birthday.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
        return `Today's ${firstName}’s birthday!`;
    } if (diffDays === 1) {
        return `Tomorrow's ${firstName}’s birthday!`;
    } if (diffDays <= 15) {
        return `${diffDays} days until ${firstName}’s birthday!`;
    }

    return false;
};

export const getCreationDate = (dateString) => {
    if (!dateString) {
        return 'N/A';
    }
    const birthday = new Date(dateString);

    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    return `${months[birthday.getUTCMonth()]} ${birthday.getUTCFullYear()}`;
};

export const getContactInteractions = (data, field) => {
    if (!data || !field) {
        return null;
    }

    const receivedCount = parseInt(data.recentMessagesReceivedCount, 10) || 0;
    const sentCount = parseInt(data.recentMessagesSentCount, 10) || 0;
    const meetingsCount = parseInt(data.recentMeetingsCount, 10) || 0;

    const interactionsResults = {
        Interactions: receivedCount + sentCount + meetingsCount,
        Emails: receivedCount + sentCount,
        Meetings: meetingsCount,
    };

    return interactionsResults[field].toLocaleString('en');
};

export const retrieveContactsId = (contacts) => {
    const idsArray = [];
    if (contacts && contacts.length !== 0) {
        contacts.forEach((contact) => idsArray.push(contact.id));
    }

    return idsArray;
};

export const formatLastInteractionDate = (date, short) => {
    if (!date) {
        return '';
    }

    const userLocale = navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const formatter = new Intl.DateTimeFormat(userLocale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        weekday: short ? 'short' : 'long',
        timeZone,
    });

    const newDate = formatter.formatToParts(new Date(date));

    const output = newDate.map((e) => e.value)?.join()?.replaceAll(',', '');

    return output.charAt(0).toUpperCase() + output.slice(1);
};
